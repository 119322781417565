import('initializers/core-js').then(async () => {
  import('initializers/gstatic');
  import('initializers/inkling');

  await Promise.all([
    import('initializers/react'),
    import('initializers/jquery'),
    import('jsplumb'),
    import('webrtc-adapter'),
    import('mark.js/dist/jquery.mark'),
  ]);

  // prettier-ignore
  await Promise.all([
    import('jquery.browser'),
    import('jquery.scrollto'),
    import('backbone.radio'),
    import('underscore'),
    import('backbone'),
  ]);

  await Promise.all([
    // import('initializers/i18n'),
    import('initializers/i18nTextbook'),
    import('initializers/underscore'),
    import('initializers/backbone'),
    import('initializers/recordrtc'),
    import('initializers/baseUrl'),
    import('initializers/recorder'),
    import('initializers/dialogs'),
    // @ts-ignore
    import('initializers/doorbell'),
    // @ts-ignore
    import('initializers/WebAudioRecorder'),
    import('initializers/em'),
    import('initializers/ajax-retry'),
    // @ts-ignore
    import('initializers/sf-chat-button'),
    import('initializers/global-handlers'),
    import('initializers/models'),
    import('initializers/ga'),
    import('initializers/app-version'),
  ]);

  // we don't use it for frontend app anymore
  // await import('initializers/sentry');

  import('./main');
});

export {};
